import React from "react"
import { Stack, Box, Typography } from "@mui/material"
import { PromptMsg, AnswerMsg, References } from "./MsgTypes"
import Logo from "../../assets/Images/logo.ico"

const Messages = ({ messages }) => {
  if (messages.length === 0) {
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <img src={Logo || "/placeholder.svg"} alt="CelcomDigi Logo" style={{ width: "100px", marginBottom: "16px" }} />
        <Typography variant="h5" color="text.primary" gutterBottom>
          Welcome to IDA
        </Typography>
        <Typography variant="body2" color="text.secondary" align="center">
          By CelcomDigi
        </Typography>
      </Box>
    )
  }

  return (
    <Stack direction="column">
      {messages.map((el, index) => (
        <React.Fragment key={index}>
          {el.type === "prompt" && <PromptMsg el={el} />}
          {el.type === "answer" && (
            <>
              <AnswerMsg el={el} />
              <References references={el.references} />
            </>
          )}
        </React.Fragment>
      ))}
    </Stack>
  )
}

export default Messages

