"use client"

import { useState, useEffect, useRef, useCallback } from "react"
import { Stack, Box } from "@mui/material"

import Header from "../../components/Conversation/Header"
import Footer from "../../components/Conversation/Footer"
import Messages from "../../components/Conversation/Messages"
import Logo from "../../assets/Images/logo.ico"

const GeneralApp = () => {
  let outputMessage = ""
  let outputReferences = []
  const [messages, setMessages] = useState([])
  const [sessionId, setSessionId] = useState(null)
  const [ws, setWs] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)
  const [wsStatus, setWsStatus] = useState("connecting")
  const [streamingMessage, setStreamingMessage] = useState({
    type: "answer",
    message: "",
    message_id: "",
    references: [],
  })
  const messagesEndRef = useRef(null)

  const handleSendMessage = (message) => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      console.log("Message from input ", message)
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          type: "prompt",
          message: message,
          message_id: `input_${new Date().getTime()}_${Math.random().toString(36).substring(2, 15)}`,
        },
      ])
      const payload = {
        message: message,
        sessionId: sessionId,
      }
      setIsProcessing(true)
      ws.send(JSON.stringify({ action: "ask", message: message, sessionId: sessionId, }))
    } else {
      console.log("WebSocket is not connected. Unable to send message.")
    }
  }

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
    }
  }, [messages])

  const requestNotificationPermission = async () => {
    if (!("Notification" in window)) {
      console.log("This browser does not support desktop notification")
    } else {
      const permission = await Notification.requestPermission()
      console.log("Notification permission:", permission)
    }
  }

  const connectWebSocket = useCallback(() => {
    setWsStatus("connecting")

    let socket

    socket = new WebSocket(process.env.REACT_APP_ASK_IDA_API)

    setWs(socket)

    socket.addEventListener("open", (event) => {
      setIsProcessing(false)

      console.log("WebSocket is open now.")
      setWsStatus("connected")
    })

    socket.addEventListener("error", (event) => {
      console.log("WebSocket connection error", event)
      setWsStatus("error")

      setIsProcessing(false)
    })

    socket.addEventListener("close", (event) => {
      console.log("WebSocket connection closed", event)
      setWsStatus("closed")
      setTimeout(() => {
        console.log("Attempting to reconnect...")
        setWsStatus("reconnecting")
        connectWebSocket()
      }, 5000)
    })

    socket.addEventListener("message", (event) => {
      console.log("Message received from server:", event.data)

      // Retrieve the message from the JSON data
      const json_data = JSON.parse(event.data)
      if (json_data.sessionId) {
        setSessionId(json_data.sessionId)
      }

      if (json_data.message === "#END_MESSAGE#") {
        const outputMessage_temp = outputMessage
        const outputReferencess_temp = outputReferences

        // Message is complete, add it to messages
        setMessages((prevMessages) => [
          ...prevMessages,
          {
            type: "answer",
            message: outputMessage_temp, // Use the accumulated message
            message_id: json_data.message_id,
            references: outputReferencess_temp || [], // Add this line to include references
          },
        ])
        // Reset streaming message
        outputMessage = ""
        setStreamingMessage({
          type: "answer",
          message: "",
          message_id: "",
          references: [], // Add this line
        })

        // Set isProcessing to false
        setIsProcessing(false)

        // Send notification for new message
        if (!document.hasFocus() && Notification.permission === "granted") {
          new Notification("New Message", {
            body: "IDA has sent you a new reply.",
            icon: Logo,
          })
        }
      } else {
        if (json_data.message) {
          // Accumulate the message
          outputMessage += json_data.message
          setStreamingMessage((prevMessage) => ({
            ...prevMessage,
            message: outputMessage,
            message_id: json_data.id,
          }))
        }
        if (json_data.references) {
          console.log("References: ", json_data.references)
          // Store the references
          outputReferences = json_data.references
          setStreamingMessage((prevMessage) => ({
            ...prevMessage,
            references: outputReferences,
          }))
        }
      }
    })
  }, [])

  useEffect(() => {
    requestNotificationPermission()
    connectWebSocket()

    return () => {
      if (ws) {
        ws.close()
      }
    }
  }, [connectWebSocket])

  return (
    <Stack padding={2} height="100vh" width="100%" alignItems="center" justifyContent="center">
      <Box
        sx={{
          height: "100%",
          width: "100%",
          maxWidth: "800px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* Chat Header */}
        <Header websocketStatus={wsStatus} />

        {/* Messages */}
        <Box
          p={4}
          sx={{
            flexGrow: 1,
            overflowY: "scroll",
            bgcolor: "#FFF",
          }}
        >
          {/* Current Messages */}
          <Messages messages={messages} />
          {/* Streaming Messages */}
          {streamingMessage.message && <Messages messages={[streamingMessage]} />}
          <div ref={messagesEndRef} />
        </Box>

        {/* Chat Footer */}
        <Footer onSendMessage={handleSendMessage} disable={isProcessing} />
      </Box>
    </Stack>
  )
}

export default GeneralApp

